var data = [{
    'meta': {
      'fa_icon': 'fa fa-shopping-bag',
      'shop_active': { 'en': 'yes', 'tr': 'yes' }
    },
    'route_path': { 'en': '/shop', 'tr': '/magaza' },
    'route_name': { 'en': 'shop', 'tr': 'magaza' },
    'component_name': { 'en': 'shop', 'tr': 'shop' },
    'caption': { 'en': 'Shop', 'tr': 'Mağaza' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': [{
      'meta': {
        'fa_icon': 'fa fa-fire',
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/campaigns', 'tr': '/kampanyalar' },
      'route_name': { 'en': 'campaigns', 'tr': 'kampanyalar' },
      'component_name': { 'en': 'campaigns', 'tr': 'campaigns' },
      'caption': { 'en': 'Campaigns', 'tr': 'Kampanyalar' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-shopping-basket',
        'id': 'user_shoppingcart',
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shoppingcart', 'tr': '/kullanici-alisverissepeti' },
      'route_name': { 'en': 'user_shoppingcart', 'tr': 'kullanici_alisverissepeti' },
      'component_name': { 'en': 'user_shoppingcart', 'tr': 'user_shoppingcart' },
      'caption': { 'en': 'Shopping Cart', 'tr': 'Alışveriş Sepeti' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-list-alt',
        'id': 'user_shopcart_bundle_order_list',
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shopcart-bundle-order', 'tr': '/kullanici-alisverissepeti-paket-siparis' },
      'route_name': { 'en': 'user_shopcart_bundle_order_list', 'tr': 'kullanici_alisverissepeti_paket_siparis' },
      'component_name': { 'en': 'user_shopcart_bundle_order_list', 'tr': 'user_shopcart_bundle_order_list' },
      'caption': { 'en': 'Orders', 'tr': 'Siparişler' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-money',
        'id': 'user_shoppingcart_payment',
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' },
      },
      'route_path': { 'en': '/user-shoppingcart-payment', 'tr': '/kullanici-alisverissepeti-odeme' },
      'route_name': { 'en': 'user_shoppingcart_payment', 'tr': 'kullanici_alisverissepeti_odeme' },
      'component_name': { 'en': 'user_shoppingcart_payment', 'tr': 'user_shoppingcart_payment' },
      'caption': { 'en': 'Payment', 'tr': 'Ödeme' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-star-o',
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-billing', 'tr': '/kullanici-fatura' },
      'route_name': { 'en': 'user_billing', 'tr': 'kullanici_fatura' },
      'component_name': { 'en': 'user_billing', 'tr': 'user_billing' },
      'caption': { 'en': 'Billing', 'tr': 'Fatura' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-hand-rock-o',
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-product-bundles-active', 'tr': '/kullanici-urun-paketleri-aktif' },
      'route_name': { 'en': 'user_product_bundles_active', 'tr': 'kullanici_urun_paketleri_aktif' },
      'component_name': { 'en': 'user_product_bundles_active', 'tr': 'user_product_bundles_active' },
      'caption': { 'en': 'My Active Products', 'tr': 'Aktif Ürünlerim' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-bar-chart'
      },
      'route_path': { 'en': '/user-statistics-view-products', 'tr': '/kullanici-istatistik-goruntuleme-urunleri' },
      'route_name': { 'en': 'user_statistics_view_products', 'tr': 'kullanici_istatistik_goruntuleme_urunleri' },
      'component_name': { 'en': 'user_statistics_view_products', 'tr': 'user_statistics_view_products' },
      'caption': { 'en': 'Statistics View Products', 'tr': 'İstatistik Görüntüleme Ürünleri' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-bar-chart'
      },
      'route_path': { 'en': '/user-machine-learning-query-products', 'tr': '/kullanici-makine-ogrenmesi-sorgulama-urunleri' },
      'route_name': { 'en': 'user_machine_learning_query_products', 'tr': 'kullanici_makine_ogrenmesi_sorgulama_urunleri' },
      'component_name': { 'en': 'user_machine_learning_query_products', 'tr': 'user_machine_learning_query_products' },
      'caption': { 'en': 'Machine Learning Products', 'tr': 'Makine Öğrenmesi Ürünleri' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-pie-chart'
      },
      'route_path': { 'en': '/user-dataset-statistics-query-products', 'tr': '/kullanici-dataset-istatistik-sorgulama-urunleri' },
      'route_name': { 'en': 'user_dataset_statistics_query_products', 'tr': 'kullanici_dataset_istatistik_sorgulama_urunleri' },
      'component_name': { 'en': 'user_dataset_statistics_query_products', 'tr': 'user_dataset_statistics_query_products' },
      'caption': { 'en': 'Dataset Statistics Query Products', 'tr': 'Dataset İstatistik Sorgulama Ürünleri' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-area-chart'
      },
      'route_path': { 'en': '/user-dataset-filter-products', 'tr': '/kullanici-dataset-filtreleme-urunleri' },
      'route_name': { 'en': 'user_dataset_filter_products', 'tr': 'kullanici_dataset_filtreleme_urunleri' },
      'component_name': { 'en': 'user_dataset_filter_products', 'tr': 'user_dataset_filter_products' },
      'caption': { 'en': 'Dataset Filter Products', 'tr': 'Dataset Filtreleme Ürünleri' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }],
  }, {
    'meta': {
      'fa_icon': 'fa fa-support',
      'login': { 'en': 'yes', 'tr': 'yes' },
      'shop_active': { 'en': 'yes', 'tr': 'yes' }
    },
    'route_path': { 'en': '/user-support-list', 'tr': '/kullanici-destek-talepleri' },
    'route_name': { 'en': 'user_support_list', 'tr': 'kullanici_destek_listesi' },
    'component_name': { 'en': 'user_support_list', 'tr': 'user_support_list' },
    'caption': { 'en': 'Support', 'tr': 'Destek Talepleri' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': [{
        'meta': {
          'fa_icon': 'fa fa-support',
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-support-details', 'tr': '/kullanici-destek-talep-detaylari' },
        'route_name': { 'en': 'user_support_details', 'tr': 'kullanici_destek_talep_detaylari' },
        'component_name': { 'en': 'user_support_details', 'tr': 'user_support_details' },
        'caption': { 'en': 'Support Details', 'tr': 'Destek Talep Detayları' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
        'list': []
      },

    ],
  }, {
    'meta': {
      'fa_icon': 'fa fa-shopping-bag',
      'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
      'login': { 'en': 'yes', 'tr': 'yes' },
      'shop_active': { 'en': 'yes', 'tr': 'yes' }
    },
    'route_path': { 'en': '/user-shop-list', 'tr': '/kullanici-magaza-listesi' },
    'route_name': { 'en': 'user_shop_list', 'tr': 'kullanici_magaza_listesi' },
    'component_name': { 'en': 'user_shop_list', 'tr': 'user_shop_list' },
    'caption': { 'en': 'Shop List', 'tr': 'Mağaza Listesi' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': [{
      'meta': {
        'fa_icon': 'fa fa-shopping-bag',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-record', 'tr': '/kullanici-magaza-kaydet' },
      'route_name': { 'en': 'user_shop_record', 'tr': 'kullanici_magaza_kaydet' },
      'component_name': { 'en': 'user_shop_record', 'tr': 'user_shop_record' },
      'caption': { 'en': 'Shop Record', 'tr': 'Mağaza Kaydet' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-shopping-bag',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-record', 'tr': '/kullanici-magaza-paket-kaydet' },
      'route_name': { 'en': 'user_shop_bundle_record', 'tr': 'kullanici_magaza_paket_kaydet' },
      'component_name': { 'en': 'user_shop_bundle_record', 'tr': 'user_shop_bundle_record' },
      'caption': { 'en': 'Shop Bundle Record', 'tr': 'Mağaza Paket Kaydet' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-shopping-bag',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/product-details', 'tr': '/urun-detayi' },
      'route_name': { 'en': 'product_details', 'tr': 'urun_detayi' },
      'component_name': { 'en': 'product_details', 'tr': 'product_details' },
      'caption': { 'en': 'Product Details', 'tr': 'Ürün Detayı' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-shopping-bag',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-list', 'tr': '/kullanici-magaza-paket-listesi' },
      'route_name': { 'en': 'user_shop_bundle_list', 'tr': 'kullanici_magaza_paket_listesi' },
      'component_name': { 'en': 'user_shop_bundle_list', 'tr': 'user_shop_bundle_list' },
      'caption': { 'en': 'Shop Bundle List', 'tr': 'Mağaza Paket Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-list-alt',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-order-list', 'tr': '/kullanici-magaza-siparis-listesi' },
      'route_name': { 'en': 'user_shop_order_list', 'tr': 'kullanici_magaza_siparis_listesi' },
      'component_name': { 'en': 'user_shop_order_list', 'tr': 'user_shop_order_list' },
      'caption': { 'en': 'Shop Order List', 'tr': 'Mağaza Sipariş Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-file-text-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-agreement-list', 'tr': '/kullanici-magaza-paket-sozlesme-listesi' },
      'route_name': { 'en': 'user_shop_bundle_agreement_list', 'tr': 'kullanici_magaza_paket_sozlesme_listesi' },
      'component_name': { 'en': 'user_shop_bundle_agreement_list', 'tr': 'user_shop_bundle_agreement_list' },
      'caption': { 'en': 'Shop Bundle Agreement List', 'tr': 'Mağaza Paket Sözleşme Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-file-text-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-agreement-record', 'tr': '/kullanici-magaza-paket-sozlesme-kaydet' },
      'route_name': { 'en': 'user_shop_bundle_agreement_record', 'tr': 'kullanici_magaza_paket_sozlesme_kaydet' },
      'component_name': { 'en': 'user_shop_bundle_agreement_record', 'tr': 'user_shop_bundle_agreement_record' },
      'caption': { 'en': 'Shop Bundle Agreement Record', 'tr': 'Mağaza Paket Sözleşme Kaydet' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-minus-square-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-product-list', 'tr': '/kullanici-magaza-paket-urun-listesi' },
      'route_name': { 'en': 'user_shop_bundle_product_list', 'tr': 'kullanici_magaza_paket_urun_listesi' },
      'component_name': { 'en': 'user_shop_bundle_product_list', 'tr': 'user_shop_bundle_product_list' },
      'caption': { 'en': 'Shop Bundle Product List', 'tr': 'Mağaza Paket Ürün Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-minus-square-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-product-record', 'tr': '/kullanici-magaza-paket-urun-kaydet' },
      'route_name': { 'en': 'user_shop_bundle_product_record', 'tr': 'kullanici_magaza_paket_urun_kaydet' },
      'component_name': { 'en': 'user_shop_bundle_product_record', 'tr': 'user_shop_bundle_product_record' },
      'caption': { 'en': 'Shop Bundle Product Record', 'tr': 'Mağaza Paket Ürün Kaydet' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-file-text-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-product-agreement-list', 'tr': '/kullanici-magaza-paket-urun-sozlesme-listesi' },
      'route_name': { 'en': 'user_shop_bundle_product_agreement_list', 'tr': 'kullanici_magaza_paket_urun_sozlesme_listesi' },
      'component_name': { 'en': 'user_shop_bundle_product_agreement_list', 'tr': 'user_shop_bundle_product_agreement_list' },
      'caption': { 'en': 'Shop Bundle Product Agreement List', 'tr': 'Mağaza Paket Ürün Sözleşme Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-file-text-o',
        'permission': { 'en': ['user_has_shop'], 'tr': ['user_has_shop'] },
        'login': { 'en': 'yes', 'tr': 'yes' },
        'shop_active': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-shop-bundle-product-agreement-record', 'tr': '/kullanici-magaza-paket-urun-sozlesme-kaydet' },
      'route_name': { 'en': 'user_shop_bundle_product_agreement_record', 'tr': 'kullanici_magaza_paket_urun_sozlesme_kaydet' },
      'component_name': { 'en': 'user_shop_bundle_product_agreement_record', 'tr': 'user_shop_bundle_product_agreement_record' },
      'caption': { 'en': 'Shop Bundle Product Agreement List', 'tr': 'Mağaza Paket Ürün Sözleşme Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, ],
  }, {
    'meta': {
      'fa_icon': 'fa fa-cogs',
      'permission': { 'en': ['admin'], 'tr': ['admin'] },
      'login': { 'en': 'yes', 'tr': 'yes' }
    },
    'route_path': { 'en': '', 'tr': '' },
    'route_name': { 'en': '', 'tr': '' },
    'component_name': { 'en': '', 'tr': '' },
    'caption': { 'en': 'Settings', 'tr': 'Ayarlar' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': [{
      'meta': {
        'fa_icon': 'fa fa-cogs',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/frontend-settings', 'tr': '/frontend-ayarlari' },
      'route_name': { 'en': 'frontend_settings', 'tr': 'frontend_ayarlari' },
      'component_name': { 'en': 'frd_edit', 'tr': 'frd_edit' },
      'caption': { 'en': 'Frontend Settings', 'tr': 'Frontend Ayarlari' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-anchor',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/register-by-authorized-person', 'tr': '/yetkili-tarafindan-kayit' },
      'route_name': { 'en': 'register_by_authorized_person', 'tr': 'yetkili_tarafindan_kayit' },
      'component_name': { 'en': 'register_by_authorized_person', 'tr': 'register_by_authorized_person' },
      'caption': { 'en': 'Register By Authorized Person', 'tr': 'Yetkili Tarafından Kullanıcı Kaydı' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-anchor',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/frontend-webunit', 'tr': '/frontend-website-birimi' },
      'route_name': { 'en': 'frontend_webunit', 'tr': 'frontend_website_birimi' },
      'component_name': { 'en': 'frd_webunit', 'tr': 'frd_webunit' },
      'caption': { 'en': 'Frontend Webunit', 'tr': 'Frontend Website Birimi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "hayır" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-anchor',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/frontend-websiteunit-list', 'tr': '/frontend-website-birim-listesi' },
      'route_name': { 'en': 'frontend_websiteunit_list', 'tr': 'frontend_website_birim_listesi' },
      'component_name': { 'en': 'frontend_websiteunit_list', 'tr': 'frontend_websiteunit_list' },
      'caption': { 'en': 'Frontend Websiteunit List', 'tr': 'Frontend Website Birim Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-user-secret',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/authorization-list', 'tr': '/yetki-listesi' },
      'route_name': { 'en': 'authorization_list', 'tr': 'yetki_listesi' },
      'component_name': { 'en': 'authorization_list', 'tr': 'authorization_list' },
      'caption': { 'en': 'Authorization List', 'tr': 'Yetki Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-user-circle',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-account-settings', 'tr': '/kullanici-hesap-ayarlari' },
      'route_name': { 'en': 'user_account_settings', 'tr': 'kullanici_hesap_ayarlari' },
      'component_name': { 'en': 'user_account_settings', 'tr': 'user_account_settings' },
      'caption': { 'en': 'User Account', 'tr': 'Kullanıcı Hesap' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-cube',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-externalapplication-list', 'tr': '/kullanici-disuygulama-listesi' },
      'route_name': { 'en': 'user_externalapplication_list', 'tr': 'kullanici_disuygulama_listesi' },
      'component_name': { 'en': 'user_externalapplication_list', 'tr': 'user_externalapplication_list' },
      'caption': { 'en': 'External Application List', 'tr': 'Dış Uygulama Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-cube',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/user-externalapplication-record', 'tr': '/kullanici-disuygulama-kaydet' },
      'route_name': { 'en': 'user_externalapplication_record', 'tr': 'kullanici_disuygulama_kaydet' },
      'component_name': { 'en': 'user_externalapplication_record', 'tr': 'user_externalapplication_record' },
      'caption': { 'en': 'External Application Record', 'tr': 'Dış Uygulama Kaydet' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }, {
      'meta': {
        'fa_icon': 'fa fa-fonticons',
        'permission': { 'en': ['admin'], 'tr': ['admin'] },
        'login': { 'en': 'yes', 'tr': 'yes' }
      },
      'route_path': { 'en': '/icon-list', 'tr': '/ikon-listesi' },
      'route_name': { 'en': 'icon_list', 'tr': 'ikon_listesi' },
      'component_name': { 'en': 'icon_list', 'tr': 'icon_list' },
      'caption': { 'en': 'Icon List', 'tr': 'İkon Listesi' },
      'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
      'show_in_menu': { "translation": { "en": "yes", "tr": "evet" }, "value": "1", "label": "yes" },
      'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      'list': []
    }],
  }, {
    'meta': {
      'fa_icon': 'fa fa-database',
      'permission': { 'en': ['admin'], 'tr': ['admin'] },
      'login': { 'en': 'yes', 'tr': 'yes' }
    },
    'route_path': { 'en': '', 'tr': '' },
    'route_name': { 'en': '', 'tr': '' },
    'component_name': { 'en': '', 'tr': '' },
    'caption': { 'en': 'Database', 'tr': 'Veritabanı' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': [{
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'auth', 'tr': 'auth' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'data_unit': { 'en': 'auth', 'tr': 'auth' },
        'route_path': { 'en': '/auth-data', 'tr': '/yetki-verileri' },
        'route_name': { 'en': 'auth_data', 'tr': 'yetki_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Auth Data', 'tr': 'Yetki Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'log', 'tr': 'log' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/log-data', 'tr': '/log-verileri' },
        'route_name': { 'en': 'log_data', 'tr': 'log_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Log Data', 'tr': 'Log Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'mail', 'tr': 'mail' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/mail-data', 'tr': '/mail-verileri' },
        'route_name': { 'en': 'mail_data', 'tr': 'mail_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Mail Data', 'tr': 'Mail Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'phone', 'tr': 'phone' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/phone-data', 'tr': '/telefon-verileri' },
        'route_name': { 'en': 'phone_data', 'tr': 'telefon_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Phone Data', 'tr': 'Telefon Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'sysvar', 'tr': 'sysvar' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/system-variable-data', 'tr': '/sistem-degiskeni-verileri' },
        'route_name': { 'en': 'system_variable_data', 'tr': 'sistem_degiskeni_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'System Variable Data', 'tr': 'Sistem Degiskeni Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr', 'tr': 'usr' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-data', 'tr': '/kullanici-verileri' },
        'route_name': { 'en': 'user_data', 'tr': 'kullanici_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Data', 'tr': 'Kullanici Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_extky', 'tr': 'usr_extky' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-externalkey-data', 'tr': '/kullanici-diserisimanahtari-verileri' },
        'route_name': { 'en': 'user_externalkey_data', 'tr': 'kullanıcı_diserisimanahtari_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Externalkey Data', 'tr': 'Kullanıcı Diserisimanahtari Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_accky', 'tr': 'usr_accky' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-accesskey-data', 'tr': '/kullanici-erisimanahtari-verileri' },
        'route_name': { 'en': 'user_accesskey_data', 'tr': 'kullanici_erisimanahtari_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Accesskey Data', 'tr': 'Kullanici Erisimanahtari Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_accky_tok', 'tr': 'usr_accky_tok' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-accesskey-token-data', 'tr': '/kullanici-erisimanahtari-token-verileri' },
        'route_name': { 'en': 'user_accesskey_token_data', 'tr': 'kullanici_erisimanahtari_token_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Accesskey Token Data', 'tr': 'Kullanici Erişim Anahtarı Token Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_tok', 'tr': 'usr_tok' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-token-data', 'tr': '/kullanici-token-verileri' },
        'route_name': { 'en': 'user_token_data', 'tr': 'kullanici_token_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Token Data', 'tr': 'Kullanici Token Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_auth', 'tr': 'usr_auth' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-auth-data', 'tr': '/kullanici-yetki-verileri' },
        'route_name': { 'en': 'user_auth_data', 'tr': 'kullanici_yetki_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Auth Data', 'tr': 'Kullanici Yetki Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shp', 'tr': 'usr_shp' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shop-data', 'tr': '/kullanici-magaza-verileri' },
        'route_name': { 'en': 'user_shop_data', 'tr': 'kullanici_magaza_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shop Data', 'tr': 'Kullanici Magaza Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shp_bund', 'tr': 'usr_shp_bund' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shop-bundle-data', 'tr': '/kullanici-magaza-paket-verileri' },
        'route_name': { 'en': 'user_shop_bundle_data', 'tr': 'kullanici_magaza_paket_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shop Bundle Data', 'tr': 'Kullanici Magaza Paket Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shp_bund_agr', 'tr': 'usr_shp_bund_agr' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shop-bundle-agreement-data', 'tr': '/kullanici-magaza-paket-sozlesme-verileri' },
        'route_name': { 'en': 'user_shop_bundle_agreement_data', 'tr': 'kullanici_magaza_paket_sozlesme_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shop Bundle Agreement Data', 'tr': 'Kullanici Magaza Paket Sozlesme Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shp_bund_pro', 'tr': 'usr_shp_bund_pro' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shop-bundle-product-data', 'tr': '/kullanici-magaza-paket-urun-verileri' },
        'route_name': { 'en': 'user_shop_bundle_product_data', 'tr': 'kullanici_magaza_paket_urun_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shop Bundle Product Data', 'tr': 'Kullanici Magaza Paket Urun Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shp_bund_pro_agr', 'tr': 'usr_shp_bund_pro_agr' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shop-bundle-product-agreement-data', 'tr': '/kullanici-magaza-paket-urun-sozlesme-verileri' },
        'route_name': { 'en': 'user_shop_bundle_product_agreement_data', 'tr': 'kullanici_magaza_paket_urun_sozlesme_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shop Bundle Product Agreement Data', 'tr': 'Kullanici Magaza Paket Urun Sozlesme Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt', 'tr': 'usr_shpcrt' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-data', 'tr': '/kullanici-alisverissepeti-verileri' },
        'route_name': { 'en': 'user_shoppingcart_data', 'tr': 'kullanici_alisverissepeti_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Data', 'tr': 'Kullanici Alisverissepeti Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt_bund', 'tr': 'usr_shpcrt_bund' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-bundle-data', 'tr': '/kullanici-alisverissepeti-paket-verileri' },
        'route_name': { 'en': 'user_shoppingcart_bundle_data', 'tr': 'kullanici_alisverissepeti_paket_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Bundle Data', 'tr': 'Kullanici Alisverissepeti Paket Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt_bund_pro', 'tr': 'usr_shpcrt_bund_pro' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-bundle-product-data', 'tr': '/kullanici-alisverissepeti-paket-urun-verileri' },
        'route_name': { 'en': 'user_shoppingcart_bundle_product_data', 'tr': 'kullanici_alisverissepeti_paket_urun_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Bundle Product Data', 'tr': 'Kullanici Alisverissepeti Paket Urun Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt_bund_pro_pay', 'tr': 'usr_shpcrt_bund_pro_pay' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-bundle-product-payment-data', 'tr': '/kullanici-alisverissepeti-paket-urun-odeme-verileri' },
        'route_name': { 'en': 'user_shoppingcart_bundle_product_payment_data', 'tr': 'kullanici_alisverissepeti_paket_urun_odeme_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Bundle Product Payment Data', 'tr': 'Kullanici Alisverissepeti Paket Urun Odeme Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_bill', 'tr': 'usr_bill' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-billing-data', 'tr': '/kullanici-fatura-verileri' },
        'route_name': { 'en': 'user_billing_data', 'tr': 'kullanici_fatura_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Billing Data', 'tr': 'Kullanici Fatura Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_extaccky', 'tr': 'usr_extaccky' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-externalaccesskey-data', 'tr': '/kullanici-diserisimanahtari-verileri' },
        'route_name': { 'en': 'user_externalaccesskey_data', 'tr': 'kullanici_diserisimanahtari_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Externalaccesskey Data', 'tr': 'Kullanici Diserisimanahtari Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'frd', 'tr': 'frd' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/frontend-data', 'tr': '/frontend-verileri' },
        'route_name': { 'en': 'frontend_data', 'tr': 'frontend_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Frontend Data', 'tr': 'Frontend Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'frd_webunit', 'tr': 'frd_webunit' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/frontend-webunit-data', 'tr': '/frontend-website-birimi-verileri' },
        'route_name': { 'en': 'frontend_webunit_data', 'tr': 'frontend_website_birimi_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'Frontend Webunit Data', 'tr': 'Frontend Website Birimi Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt_bund_agr', 'tr': 'usr_shpcrt_bund_agr' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-bundle-agreement-data', 'tr': '/kullanici-alisverissepeti-paket-sozlesme-verileri' },
        'route_name': { 'en': 'user_shoppingcart_bundle_agreement_data', 'tr': 'kullanici_alisverissepeti_paket_sozlesme_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Bundle Agreement Data', 'tr': 'Kullanici Alisverissepeti Paket Sozlesme Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
      {
        'meta': {
          'fa_icon': 'fa fa-fire',
          'data_unit': { 'en': 'usr_shpcrt_bund_pro_agr', 'tr': 'usr_shpcrt_bund_pro_agr' },
          'permission': { 'en': ['admin'], 'tr': ['admin'] },
          'login': { 'en': 'yes', 'tr': 'yes' }
        },
        'route_path': { 'en': '/user-shoppingcart-bundle-product-agreement-data', 'tr': '//kullanici-alisverissepeti-paket-urun-sozlesme-verileri' },
        'route_name': { 'en': 'user_shoppingcart_bundle_product_agreement_data', 'tr': 'kullanici_alisverissepeti_paket_urun_sozlesme_verileri' },
        'component_name': { 'en': 'db', 'tr': 'db' },
        'caption': { 'en': 'User Shoppingcart Bundle Product Agreement Data', 'tr': 'Kullanici Alisverissepeti Paket Urun Sozlesme Verileri' },
        'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
        'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
      },
    ]
  },
  {
    'meta': {
      'fa_icon': 'fa fa-fire',
      'iframe_mode': { 'en': 'yes', 'tr': 'yes' },
    },
    'route_path': { 'en': 'wanalyzer-cell-calculate-iframe', 'tr': 'wanalyzer-cell-calculate-iframe' },
    'route_name': { 'en': 'wanalyzer-cell-calculate-iframe', 'tr': 'wanalyzer-cell-calculate-iframe' },
    'component_name': { 'en': 'wanalyzer_cell_calculate_iframe', 'tr': 'wanalyzer_cell_calculate_iframe' },
    'caption': { 'en': 'Wanalyzer Cell Calculate', 'tr': 'Wanalyzer Hücre Hesapla' },
    'is_dashboard': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'show_in_menu': { "translation": { "en": "no", "tr": "hayır" }, "value": "0", "label": "no" },
    'click_type': { "translation": { "en": "application page", "tr": "uygulama sayfası" }, "value": "0", "label": "application page" },
    'list': []
  }
];


export { data };
