import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  login (query, data) {
    let route = '';
    if (query) {
      route = 'v1/login?' + query;
    } else {
      route = 'v1/login';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  password_reset_by_mail (query, data) {
    let route = '';
    if (query) {
      route = 'v1/password/reset/by/mail?' + query;
    } else {
      route = 'v1/password/reset/by/mail';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  password_reset_by_mail_approve (query, data) {
    let route = '';
    if (query) {
      route = 'v1/password/reset/by/mail/approve?' + query;
    } else {
      route = 'v1/password/reset/by/mail/approve';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_accesskey_record (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/accesskey/record?' + query;
    } else {
      route = 'v1/user/accesskey/record';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_accesskey_token_create (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/accesskey/token/create?' + query;
    } else {
      route = 'v1/user/accesskey/token/create';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
