import * as MutationTypes from '../mutation-types';
import Vue from 'vue';

const state = {
  device: {
    'isMobile': false,
    'isTablet': false
  },
  websocket: {
    'status': false,
  },
  sidebar: {
    'opened': false,
    'hidden': false,
    'control_on_user': false
  },
  patient: {
    'selected': false
  },
  modal: {
    'show': false,
    'data': {}
  },
  loading: {
    'status': false,
    'data': { 'label': '' }
  },
  user: {
    'status': 0,
    'data': {}
  },
  screen_size: {
    'width': 0,
    'height': 0
  },
  frontend_settings: {
    'status': 0,
    'data': {}
  },
  frontend_websiteunit: {
    'status': 0,
    'data': []
  },
  menu_data: {
    'status': 0,
    'data': {'list': []}
  },
  lang: 'tr',
  lang_list: {
    'status': 0,
    'data': {'list': []}
  },
  currency_type: {
    'value': '0',
    'label': '₺'
  },
  currency_type_list: {
    'status': 0,
    'data': {'list': []}
  },
  lang_translation: {
    'status': 0,
    'data': {}
  },
  shop_cart_list: {
    'status': 0,
    'data': {}
  },
  shop_cart_order_list: {
    'status': 0,
    'data': {}
  },
};

const mutations = {
  [MutationTypes.MutationToggleDevice](state, device) {
    state.device.isMobile = device === 'mobile';
    state.device.isTablet = device === 'tablet';
  },

  [MutationTypes.MutationToggleSidebar](state, opened) {
    if (state.device.isMobile) {
      state.sidebar.opened = opened;
    } else {
      state.sidebar.opened = true;
    }
  },

  [MutationTypes.MutationLoading](state, loading_data) {
    state.loading = loading_data;
  },

  [MutationTypes.MutationSidebarControl](state, close_or_open) {
    state.sidebar.hidden = close_or_open;
  },

  [MutationTypes.MutationPatientData](state, data) {
    if (data) {
      state.patient = data;
      state.patient.selected = true;
    }
  },

  [MutationTypes.MutationWebSocket](state, websocket_status) {
    state.websocket.status = websocket_status;
  },

  [MutationTypes.MutationLang](state, lang) {
    state.lang = lang;
  },

  [MutationTypes.MutationScreenSize](state, screen_size) {
    state.screen_size = screen_size;
  },

  [MutationTypes.MutationModal](state, data) {
    state.modal.data = data;
  },

  [MutationTypes.MutationModalShow](state, status) {
    state.modal.show = status;
  },

  [MutationTypes.MutationUser](state, data) {
    state.user.status = data.status;
    state.user.data = data.data;
  },

  [MutationTypes.MutationFrontendSettings](state, data) {
    state.frontend_settings.status = data.status;
    state.frontend_settings.data = data.data;
  },

  [MutationTypes.MutationFrontendWebsiteunit](state, data) {
    state.frontend_websiteunit.status = data.status;
    state.frontend_websiteunit.data = data.data;
  },

  [MutationTypes.MutationMenuData](state, data) {
    state.menu_data.status = data.status;
    state.menu_data.data = data.data;
  },

  [MutationTypes.MutationLangList](state, data) {
    state.lang_list.status = data.status;
    state.lang_list.data = data.data;
  },

  [MutationTypes.MutationLangTranslation](state, data) {
    state.lang_translation.status = data.status;
    state.lang_translation.data = data.data;
  },

  [MutationTypes.MutationShopCartList](state, data) {
    state.shop_cart_list.status = data.status;
    state.shop_cart_list.data = data.data;
  },

  [MutationTypes.MutationShopCartOrderList](state, data) {
    state.shop_cart_order_list.status = data.status;
    state.shop_cart_order_list.data = data.data;
  },

  [MutationTypes.MutationCurrencyType](state, currency_type) {
    // console.log('currency_type : ', currency_type);
    state.currency_type.value = currency_type.value;
    state.currency_type.label = currency_type.label;
  },

  [MutationTypes.MutationCurrencyTypeList](state, data) {
    state.currency_type_list.status = data.status;
    state.currency_type_list.data = data.data;
  },

};

export default {
  state,
  mutations
};
