const StoreApp = state => state.app;
const StoreDevice = state => state.app.device;
const StoreLang = state => state.app.lang;
const StoreLoading = state => state.app.loading;
const StoreModal = state => state.app.modal;
const StorePatient = state => state.app.patient;
const StoreScreen_size = state => state.app.screen_size;
const StoreSidebar = state => state.app.sidebar;
const StoreUser = state => state.app.user;
const StoreVersion = state => state.app.version;
const StoreWebsocket = state => state.app.websocket;
const StoreFrontendSettings = state => state.app.frontend_settings;
const StoreFrontendWebsiteunit = state => state.app.frontend_websiteunit;
const StoreMenuData = state => state.app.menu_data;
const StoreLangList = state => state.app.lang_list;
const StoreLangTranslation = state => state.app.lang_translation;
const StoreShopCartList = state => state.app.shop_cart_list;
const StoreShopCartOrderList = state => state.app.shop_cart_order_list;
const StoreCurrencyType = state => state.app.currency_type;
const StoreCurrencyTypeList = state => state.app.currency_type_list;

export {
  StoreApp,
  StoreDevice,
  StoreLang,
  StoreLoading,
  StoreModal,
  StorePatient,
  StoreScreen_size,
  StoreSidebar,
  StoreUser,
  StoreVersion,
  StoreWebsocket,
  StoreFrontendSettings,
  StoreFrontendWebsiteunit,
  StoreMenuData,
  StoreLangList,
  StoreLangTranslation,
  StoreShopCartList,
  StoreShopCartOrderList,
  StoreCurrencyType,
  StoreCurrencyTypeList,
};
