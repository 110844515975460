import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  frontend_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/get?' + query;
    } else {
      route = 'v1/frontend/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  frontend_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/list?' + query;
    } else {
      route = 'v1/frontend/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  frontend_save (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/save?' + query;
    } else {
      route = 'v1/frontend/save';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  frontend_websiteunit_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/websiteunit/get?' + query;
    } else {
      route = 'v1/frontend/websiteunit/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  frontend_websiteunit_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/websiteunit/list?' + query;
    } else {
      route = 'v1/frontend/websiteunit/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  frontend_website_unit_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/websiteunit/new?' + query;
    } else {
      route = 'v1/frontend/websiteunit/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};
